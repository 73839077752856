import catsPerMarket from '@data/cats-x-markets.json';
import config from '@data/config.json';
import marketData from '@data/markets.json';
import productsPerMarket from '@data/sku-x-market.json';
import structuredDataXMarket from '@data/structured-data-x-market.json';
import { CategoryListQuery, ProductQuery, StringMultiLocaleField } from '@graphql/generated';
import { AltsType, Locales } from '@model/common';
import _ from 'lodash';

export const subPaths = {
  categories: {
    it: 'categorie',
    en: 'categories',
    de: 'kategorien',
    fr: 'categories',
  },
  products: {
    it: 'prodotti',
    en: 'products',
    de: 'produkte',
    fr: 'produits',
  },
};

export function getAvailableCategories(
  categoryList: CategoryListQuery['categoryList'],
  params: { market: string; currency: string; lang: Locales | string | any }
): CategoryListQuery['categoryList'] {
  const whiteList = catsPerMarket
    .find(
      (i) => i.marketPaths.currency === params.currency && i.marketPaths.market === params.market
    )
    ?.categories.map((c) => c.id);
  const availableCategories = categoryList.filter((c) => whiteList.includes(c.id));
  return availableCategories;
}

export function getLocales(): string[] {
  return config.availableLocales.locales;
}

export function getAlternativePaths(
  type: string,
  alts: StringMultiLocaleField[],
  parent: StringMultiLocaleField[] = []
): AltsType {
  const paths = alts.reduce((obj, t) => {
    const { locale, value } = t;
    let prefix = '';
    if (type === 'product') {
      prefix = subPaths.products[locale];
    } else if (type === 'category') {
      prefix = subPaths.categories[locale];
      if (parent.length) {
        const parentTranslation = parent.find((a) => a.locale === locale);
        prefix = `${prefix}/${parentTranslation.value}`;
      }
    }
    const path = `${prefix ? prefix + '/' : ''}${value}`;
    return {
      ...obj,
      [locale]: { path, value, locale },
    };
  }, {} as AltsType);
  return paths;
}

export const getPaths = (langs: string[] = []) => {
  const markets = marketData?.map(({ market_name }) => {
    const [market, currency] = market_name.split(' ');
    return { market: market.toLowerCase(), currency: currency.toLowerCase() };
  });

  const paths = langs
    .map((lang) => {
      return _.uniqBy(markets, 'market').map(({ market, currency }) => {
        return { currency, market, lang };
      });
    })
    .flat();

  return _.orderBy(paths, ['currency', 'market', 'lang']);
};

// replace export const dynamicParams = false
export const isValidPath = (params: {
  market: string;
  currency: string;
  lang: Locales | string | any;
}) => {
  const paths = getPaths(getLocales());

  const isValidPath = paths.some(
    ({ currency, market, lang }) =>
      currency === params.currency && market === params.market && lang === params.lang
  );

  return isValidPath;
};

export const parseUrl = (url: string) => {
  const [protocol, withoutProtocol] = url.split('//');
  const [withoutQuery, query] = withoutProtocol.split('?');
  const [host, ...path] = withoutQuery.split('/');
  const [country, currency, locale] = path;
  const data = {
    protocol,
    host,
    country,
    currency,
    locale,
    query,
    withoutQuery,
    current: url,
    baseUrl: `${protocol}//${host}/${country}/${currency}/${locale}/`,
  };
  return data;
};

export const isHome = (pathname: string) => {
  // Definiamo un'espressione regolare per verificare il formato /country/currency/locale
  const regex = /^\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;

  // Testiamo se il pathname rispetta il formato
  return regex.test(pathname);
};

export function getSkusInMarket(market: string, currency: string) {
  const whiteList = productsPerMarket.find(
    (item) => item.marketPaths.market === market && item.marketPaths.currency === currency
  );
  return whiteList.results.map((r) => r.code);
}

export function getAvailableProductInfo(
  params: { market: string; currency: string; lang: Locales | string | any; product: string },
  product: ProductQuery['product']
) {
  const { market, currency } = params;
  const whiteList = structuredDataXMarket.find(
    (item) => item.marketPaths.market === market && item.marketPaths.currency === currency
  );
  const productInfo = whiteList.results.find((r) => r.code == product.skuCode);
  return productInfo;
}

export function getAvailableMarketsForCategory(id: string) {
  const results = catsPerMarket.filter((i) => {
    const ids = i.categories.map((c) => c.id);
    return ids.includes(id);
  });
  return results.map((i) => i.marketPaths);
}
